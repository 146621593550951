<div class="m-lg-5 m-md-3 m-1">
  <h1>Datenschutzerklärung</h1>
  <p class="text-praxis-primary">Allgemeiner Hinweis und Pflichtinformationen</p>

  <div class="mt-5 mr-3">
    <ol>
      <li class="font-weight-bold">Benennung der verantwortlichen Stelle</li>
      <div class="text-justify pt-3">
        <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
        <app-contact></app-contact>
        <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
      </div>

      <li class="font-weight-bold pb-3">Widerruf Ihrer Einwilligung zur Datenverarbeitung</li>
      <p class="text-justify">Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung
        möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
        formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
        Widerruf unberührt.</p>

      <li class="font-weight-bold pb-3">Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</li>
      <p class="text-justify">Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich
        datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
        unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren
        Kontaktdaten bereit:
        <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>.</p>

      <li class="font-weight-bold pb-3">Recht auf Datenübertragbarkeit</li>
      <p class="text-justify">Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
        Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
        Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen
        anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

      <li class="font-weight-bold pb-3">Recht auf Auskunft, Berichtigung, Sperrung, Löschung</li>
      <p class="text-justify">Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf
        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und
        den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
        Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im
        Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>

      <li class="font-weight-bold pb-3">SSL- bzw. TLS-Verschlüsselung</li>
      <p class="text-justify">Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an
        uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die
        Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an
        der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

      <li class="font-weight-bold pb-3">Server-Log-Dateien</li>
      <p class="text-justify">In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch
        Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
      <ul class="m-2">
        <li>Besuchte Seite auf unserer Domain</li>
        <li>Datum und Uhrzeit der Serveranfrage</li>
        <li>Browsertyp und Browserversion</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>IP-Adresse</li>
      </ul>
      <p class="text-justify">Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der
        Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
        oder vorvertraglicher Maßnahmen gestattet.</p>

      <li class="font-weight-bold pb-3">Cookies</li>
      <p class="text-justify">Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf
        Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu
        machen. </p>
      <p class="text-justify">Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer
        Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese
        selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.</p>
      <p class="text-justify">Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken
        oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms
        von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer
        Website zur Folge haben.</p>
      <p class="text-justify">Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der
        Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf
        Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an
        der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern
        die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerklärung
        separat behandelt.</p>
      <p><small>Quelle: Datenschutz-Konfigurator von
        <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">mein-datenschutzbeauftragter.de</a></small>
      </p>
    </ol>
  </div>
</div>
