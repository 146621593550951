<div class="m-lg-5 m-md-3 m-1">
  <h1>Unsere Sprechzeiten</h1>
  <div class="mt-5 mb-5">
    <table>
      <tr>
        <th scope="row">Montag</th>
        <td>&nbsp;</td>
        <td class="text-right">9:00</td>
        <td class="text-right">-</td>
        <td class="text-right">12:00</td>
        <td>&nbsp;</td>
        <td class="text-right">14:30</td>
        <td class="text-right">-</td>
        <td class="text-right">17:00</td>
      </tr>
      <tr>
        <th scope="row">Dienstag</th>
        <td>&nbsp;</td>
        <td class="text-right">9:00</td>
        <td class="text-right">-</td>
        <td class="text-right">12:00</td>
        <td>&nbsp;</td>
        <td class="text-right">14:30</td>
        <td class="text-right">-</td>
        <td class="text-right">17:00</td>
      </tr>
      <tr>
        <th scope="row">Donnerstag</th>
        <td>&nbsp;</td>
        <td class="text-right">9:00</td>
        <td class="text-right">-</td>
        <td class="text-right">12:00</td>
        <td>&nbsp;</td>
        <td class="text-right">14:30</td>
        <td class="text-right">-</td>
        <td class="text-right">17:00</td>
      </tr>
      <tr>
        <th scope="row">Freitag</th>
        <td>&nbsp;</td>
        <td class="text-right">9:00</td>
        <td class="text-right">-</td>
        <td class="text-right">12:00</td>
        <td>&nbsp;</td>
        <td class="text-right" colspan="3"></td>
      </tr>
    </table>
  </div>

  <h1>BG-Sprechstunde</h1>
  <h3>Arbeits-, Schul-, Wege-<br/> und Kindergartenunfall</h3>
  <div class="mt-5 mb-5">
    <table>
      <tr>
        <th scope="row">Montag - Freitag</th>
        <td>&nbsp;</td>
        <td class="text-right">8:00</td>
        <td class="text-right">-</td>
        <td class="text-right">18:00</td>
        <td>&nbsp;</td>
        <td class="text-right" colspan="3"></td>
      </tr>
    </table>
  </div>

  <h1>Notfallsprechstunde</h1>
  <div class="mt-5 mb-5">
    <table>
      <tr>
        <th scope="row">Montag - Freitag</th>
        <td>&nbsp;</td>
        <td class="text-right">8:15</td>
        <td class="text-right">-</td>
        <td class="text-right">9:00</td>
      </tr>
      <tr>
        <th scope="row">Mo., Di., Do.</th>
        <td>&nbsp;</td>
        <td class="text-right">14:30</td>
        <td class="text-right">-</td>
        <td class="text-right">15:00</td>
      </tr>
    </table>
  </div>
</div>


<div class="m-lg-5 m-md-3 m-1">
  <h3>Sondersprechstunde zu den Themen:</h3>

  <ul>
    <li>Arthrose- / Osteoporosebehandlung</li>
    <li>Knorpelaufbaukuren (Homoöpathisch, Nahrungsergänzung, Hyaluronsäure, Eigenblut)</li>
    <li>Schuheinlagen im Breiten- und Hochleistungssport</li>
    <li>Nahrungsergänzungs- u. Vitalstoffberatung</li>
    <li>Renten- und Versicherungsgutachten</li>
    <li>Impfungen</li>
    <li>Sondersprechstunden finden nur nach Terminvereinbarung statt.</li>
  </ul>

  <p class="text-praxis-primary">Bitte vereinbaren Sie rechtzeitig Ihre Termine mit uns. Vielen Dank!</p>
</div>
