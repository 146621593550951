<div class="m-lg-5 m-md-3 m-1">
  <h1>Impressum</h1>

  <p class="text-praxis-primary">Informationen gemäß § 5 TMG</p>

  <app-contact class="m-2"></app-contact>

  <div class="mt-3 mb-5">
    <h5>Zuständige Ärztekammer:</h5>
    <p>Landesärztekammer Nordrhein: <a href="http://www.aekno.de/">www.aekno.de</a></p>

    <h5>Zuständige Kassenärztliche Vereinigung:</h5>
    <p>Kassenärztliche Vereinigung Nordrhein <a href="http://www.kvno.de/">www.kvno.de</a></p>

    <h5>Berufsbezeichnung:</h5>
    <p>Arzt (verliehen in Deutschland)</p>

    <h5>Berufsrechtliche Regelungen:</h5>
    <p>Die Berufsordnung ist zu beziehen, beziehungsweise einzusehen bei der zuständigen Ärztekammer <a
      href="http://www.aekno.de/">www.aekno.de</a></p>

    <h5>Redaktionell Verantwortlicher:</h5>
    <p>Dr. med. Gunnar G. Pobel</p>

    <p>Alle Rechte vorbehalten</p>
  </div>


  <h3>Rechtlicher Hinweis</h3>

  <div class="mr-3">
    <ol>
      <li class="font-weight-bold pb-3">Inhalt des Online-Angebotes</li>
      <p class="text-justify">
        Die auf unseren Internetseiten zur Verfügung gestellten Inhalte sind unseres Erachtens verlässlich und
        sorgfältig erarbeitet. Wir übernehmen jedoch keine Gewährleistung für die Vollständigkeit, Richtigkeit und
        Genauigkeit sämtlicher Inhalte auf den Webseiten. Wir übernehmen keinerlei Gewähr für die Aktualität,
        Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den
        Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
        der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen
        verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches
        oder grob fahrlässiges Verschulden vorliegt.</p>
      <p class="text-justify">
        Die auf unserer Homepage für Sie bereitgestellten Gesundheitsinformationen dürfen
        weder als Ersatz für professionelle Beratung und/oder Behandlung durch approbierte Ärzte und Zahnärzte angesehen
        werden, noch dürfen aufgrund der Informationen eigenständig Diagnosen gestellt oder Behandlungen begonnen oder
        abgesetzt werden. Beachten Sie bitte, dass unsere Gesundheitsinformationen nicht den Arztbesuch ersetzen.</p>
      <p class="text-justify">
        Alle Angebote sind frei bleibend und unverbindlich. Wir behalten es uns ausdrücklich vor, Teile der Seiten oder
        das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung
        zeitweise oder endgültig einzustellen.</p>

      <li class="font-weight-bold pb-3">Verweise und Links</li>
      <p class="text-justify">
        Trotz sorgfältiger Kontrolle externer Links übernehmen wir keine Haftung für deren Inhalte. Für den Inhalt
        verlinkter Stellen sind ausschließlich deren Betreiber verantwortlich. Bei direkten oder indirekten Verweisen
        auf fremde Internet-Seiten ("Links"), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine
        Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem wir von den Inhalten Kenntnis hat und
        es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Wir
        erklären hiermit ausdrücklich, dass zum Zeitpunkt der Link-Setzung keine illegalen Inhalte auf den zu
        verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die
        Urheberschaft der gelinkten/verknüpften Seiten haben wir keinerlei Einfluss. Deshalb distanziert er sich
        hiermit ausdrücklich von allen Inhalten aller gelinkten/verknüpften Seiten, die nach der Link-Setzung
        verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internet-Angebotes gesetzten Links
        und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen und
        Mailing-Listen. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus
        der Nutzung oder Nichtnutzung solcherart dargebotenen Informationen entstehen, haftet allein der Anbieter der
        Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung
        lediglich verweist.</p>


      <li class="font-weight-bold pb-3">Urheber- und Kennzeichenrecht</li>
      <p class="text-justify">
        Wir sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente,
        Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte
        zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb
        des Internet-Angebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen
        uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen
        eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen
        nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte
        bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente,
        Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
        Zustimmung des Autors nicht gestattet.</p>


      <li class="font-weight-bold pb-3">Rechtswirksamkeit dieses Haftungsausschlusses</li>
      <p class="text-justify">
        Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite
        verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht
        mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und
        ihrer Gültigkeit davon unberührt.</p>

      <li class="font-weight-bold pb-3">Copyright</li>
      <p class="text-justify">
        Vervielfältigung der hier veröffentlichten Inhalte und Grafiken nur mit ausdrücklicher schriftlicher
        Genehmigung.</p>
    </ol>
  </div>
</div>
