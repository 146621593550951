<p>Bei meiner Praxis handelt es sich um die erste Niederlassung für Chirurgie der Region, die Ihren Standort
  mittlerweile seit fast 50 Jahren im Zentrum von Moers hat. Ich betreibe die Praxis in 3. Generation seit dem
  01.07.2006. Hierbei werden mittlerweile sämtliche Erkrankungen aus Chirurgie, Orthopädie und Unfallchirurgie
  behandelt. Durch meine Zulassung als Durchgangsarzt legen wir auch einen besonderen Schwerpunkt auf die Behandlung
  von Arbeits-, Schul- und Wegeunfällen. Dabei besteht eine enge Zusammenarbeit mit den Krankenhäusern der Umgebung,
  der Berufsgenossenschaften und Rehakliniken.</p>
<p>Für mein Team und mich stehen Sie als Patient mit Ihren individuellen gesundheitlichen Fragen im Zentrum unserer
  Bemühungen. Da wir erkannt haben, dass ein bestimmtes Symptom sich häufig nicht für sich alleine, sondern meistens
  im Zusammenhang mit anderen Symptomen und individuellen Einflussfaktoren betrachtet werden muss, haben wir uns
  zunehmend im Sinne der ganzheitlichen Medizin weiterentwickelt. Wir haben unseren gesamten Praxisablauf darauf
  abgestimmt, um für Sie optimale Präventions- und Therapieergebnisse zu erzielen. Nur durch die Fähigkeit Sie so
  vollständig und ganzheitlich wie möglich zu untersuchen hat man die Möglichkeit die richtige Diagnose und Ursache
  zu ermitteln, um Sie optimal präventiv beraten und behandeln zu können.</p>
<p>Wir sichern die Qualität meiner Praxis durch eine patientenorientierte Betreuung und Aufklärung sowie durch
  leitlinienorientierte Organisations- und Behandlungsabläufe. Hierfür nimmt das gesamte Praxisteam regelmäßig an
  Weiterbildungen teil. Desweiteren pflegen wir eine optimale Kommunikation mit den umliegenden Krankenhäusern sowie
  Ärzten aus dem Umfeld der Stadt Moers.</p>
<p>Sie nach einem schweren Unfall, bei einer schweren Erkrankung oder nur bei dem Wunsch nach langanhaltender
  Gesundheit zu begleiten ist unser Anliegen. Dafür setzen wir unser umfassendes Wissen und unsere Erfahrung
  ein.</p>
<p>Das Motto unsere Praxis lautet daher <span class="Fett">„Gesund bis ins hohe Alter“</span>. Hierfür nehmen wir
  uns gerne Zeit.</p>
<p>Auf den folgenden Seiten biete ich Ihnen umfassende Informationen über meine Praxis für Chirurgie, Orthopädie und
  Unfallchirurgie sowie Angaben über meine Praxisschwerpunkte und Leistungen.</p>

<p>
  <br/>
  Ich wünsche Ihnen dabei viel Spaß,
  <br/>
  <br/>
  <span class="has-text-black is-bold">Ihr Dr. G. G. Pobel</span>
  <br/>
  <span class="has-text-black">FA für Chirurgie, Orthopädie und Unfallchirurgie</span>
</p>
