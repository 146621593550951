import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-d-arzt-tile',
  templateUrl: './d-arzt-tile.component.html',
  styleUrls: ['./d-arzt-tile.component.css']
})
export class DArztTileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
