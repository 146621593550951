import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';

import {CoreModule} from './core/core.module';
import {LeistungenModule} from './leistungen/leistungen.module';
import {LeistungenAllComponent} from './leistungen/components/leistungen-all/leistungen-all.component';
import { BeschreibungComponent } from './beschreibung/beschreibung.component';
import { OeffnungszeitenComponent } from './oeffnungszeiten/oeffnungszeiten.component';
import {ImpressumComponent} from './core/components/impressum/impressum.component';
import {DatenschutzComponent} from './core/components/datenschutz/datenschutz.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'leistungen', component: LeistungenAllComponent},
  {path: 'oeffnungszeiten', component: OeffnungszeitenComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  {path: 'impressum', component: ImpressumComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BeschreibungComponent,
    OeffnungszeitenComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    CoreModule,
    LeistungenModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
