import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactComponent} from './components/contact/contact.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OpeningTableComponent} from './components/opening-table/opening-table.component';
import {DatenschutzComponent} from './components/datenschutz/datenschutz.component';
import {ImpressumComponent} from './components/impressum/impressum.component';


@NgModule({
  declarations: [
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    OpeningTableComponent,
    DatenschutzComponent,
    ImpressumComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule
  ],
  exports: [
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    OpeningTableComponent
  ]
})
export class CoreModule {
}
