import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DArztComponent} from './components/d-arzt/d-arzt.component';
import {DArztTileComponent} from './components/d-arzt-tile/d-arzt-tile.component';
import { LeistungenAllComponent } from './components/leistungen-all/leistungen-all.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [DArztComponent, DArztTileComponent, LeistungenAllComponent],
  exports: [
    DArztTileComponent,
    LeistungenAllComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule
  ]
})
export class LeistungenModule {
}
