

<tr>
  <th scope="row">Notfallsprechstunde</th>
  <td>&nbsp;&nbsp;&nbsp;</td>
  <td class="text-right" colspan="3">täglich</td>
  <td>&nbsp;&nbsp;&nbsp;</td>
  <td class="text-right"> 8:15</td>
  <td class="text-right">-</td>
  <td class="text-right">8:45</td>
</tr>
