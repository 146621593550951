<div class="card" style="width: 20rem;">
  <img class="card-img-top" src="../../../../assets/img/bg-schreiben.png" alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">Durchgangsarzt</h5>
    <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
    <div class="card-text">
      <p>Durch meine langjährige Tätigkeit als stellvertretender Durchgangsarzt in einer Unfallchirurgischen Klinik mit
        Zulassung zum Schwerverletztenverfahren habe ich die Genehmigung sämtliche Arbeits-, Dienst- und Schulunfälle
        sowie
        Berufserkrankungen im Auftrag der Berufsgenossenschaften und Unfallkassen zu behandeln.</p>
      <a href="#" class="btn btn-primary">Mehr...</a>
    </div>
  </div>
</div>
