<nav class="navbar navbar-expand-md fixed-top bg-praxis-primary navbar-light shadow">
  <div class="container-xl">
    <a class="navbar-brand" href="#">
      <img src="assets/img/logo-pobel.png" width="75" alt="Praxis Dr. Pobel"/>
    </a>

    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav mr-auto">

        <li class="nav-item">
          <a class="nav-link" (click)="isCollapsed = true" routerLink="/">Home <span class="sr-only">(current)</span></a>
        </li>

        <li class="nav-item">
          <a class="nav-link" (click)="isCollapsed = true" routerLink="/oeffnungszeiten">Öffnungszeiten</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

