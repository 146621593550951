<h3 class="">Dr. med. Gunnar G. Pobel</h3>
<h4 class="mb-4 text-muted">FA für Chirurgie, Orthopädie<br/> und Unfallchirurgie</h4>
<address>
  Bankstraße 10<br/>
  47441 Moers<br/>
  Telefon: <a href="tel:+49284123932">02841-23932</a><br/>
  Telefax: 02841-1735091<br>
</address>

<p>eMail: <a href="mailto:praxis@pobel.de">praxis@pobel.de</a><br/>
  Web: <a href="https://www.moers-sportmedizin.de">www.moers-sportmedizin.de</a></p>
